import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { User } from "../util";
import { fetchCurrentUser } from "../BytebeamClient";
import { HARDWARE_TYPES } from "../components/Screens/util";

// Placeholder dummy user
const dummyUser: User = {
  id: "",
  name: "",
  email: "",
  tenants: [] as Array<string>,
  settings: {
    theme: "",
    current_release_id: "",
    changelog_id: "",

    get_started: { deviceType: {}, skip: false, selectedDevice: undefined },
  },
  tenant_id: "",
  "tenant-settings": {
    common_settings: {
      pin_metadata: [],
    },
    "serial-key": null,
    dashboard_settings: {
      custom_time_ranges: {},
    },
    show_tabs: null,
    logo: {
      light: "",
      dark: "",
    },
    favicon: {
      light: "",
      dark: "",
    },
    hardware_type: HARDWARE_TYPES[0],
  },
  role: {
    id: -1,
    name: "",
    permissions: {
      devices: {} as { [key: string]: "all" | string[] },
      tables: {} as { [key: string]: "all" | string[] },
      homepage: "",
      viewMetadata: [] as string[],
      editMetadata: [] as string[],
      dashboardPermittedShareRoles: [] as number[],
      createDashboards: false,
      allowedActions: [] as string[],
      viewFiles: false,
      editFiles: false,
      viewFirmwares: false,
      editFirmwares: false,
      viewDeviceConfigs: false,
      editDeviceConfigs: false,
      viewStreams: false,
      editStreams: false,
      viewUsers: false,
      editUsers: false,
      viewRoles: false,
      editRoles: false,
      viewActionTypes: false,
      editActionTypes: false,
      showDeviceManagementTab: false,
      showDashboardsTab: false,
      showActionsTab: false,
      viewMetadataKeys: false,
      editMetadataKeys: false,
      editTenantSettings: false,
      allowCreatingDevices: false,
      allowMarkActionAsCompleted: false,
      reviewActions: false,
    },
  },
};

// Creating a UserContext with default values
export const UserContext = createContext({
  user: dummyUser,
  getCurrentUser: () => Promise.resolve(),
});

// Custom hook to access UserContext
export function useUser() {
  const userContext = useContext(UserContext);
  return userContext;
}

type UserContextProviderProps = {
  readonly children: React.ReactNode;
};

export function UserContextProvider(props: UserContextProviderProps) {
  const [user, setUser] = useState<User>(dummyUser);

  // Function to fetch and set the current user
  async function getCurrentUser(): Promise<void> {
    try {
      let currentUser = await fetchCurrentUser();
      if (currentUser === undefined || currentUser === null) {
        // If fetch current user api fails recall the function
        await getCurrentUser();
        return;
      } else {
        setUser({
          ...currentUser,
          // Enable the code block below to handle light mode related implementation
          // // ==============================================================================
          // settings: {
          //   theme: "dark",
          //   current_release_id: "",
          //   changelog_id: "",
          //   get_started: {
          //     deviceType: {
          //       Linux: {
          //         install_sdk: true,
          //       },
          //     },
          //     selectedDevice: "Linux",
          //     skip: false,
          //   },
          // },
          // // ==============================================================================
        });
        return;
      }
    } catch (err) {
      console.log("Error occurred when fetching user");
    }
  }

  // Fetch the current user on component mount
  useEffect(() => {
    getCurrentUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useMemo hook to create a memoized contextValue variable that depends on the user state.
  // The contextValue will be recreated only when the user state changes,
  // preventing unnecessary re-renders of consuming components.
  const contextValue = useMemo(
    () => ({ user, getCurrentUser }),
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}
