import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DeviceManagement from "./components/Screens/DeviceManagement/deviceManagement";
import Dashboards from "./components/Screens/Dashboards/Dashboards";
import LoginForm from "./components/Screens/GetStarted/LogInForm";
import GetStartedScreen from "./components/Screens/GetStarted/GetStartedPage";
import history from "history/browser";
import SettingsManagement from "./components/Screens/Settings/SettingsManagement";
import { DisplayIf } from "./components/Screens/util";
import WelcomeScreen from "./components/Screens/GetStarted/WelcomePage";
import {
  fetchCurrentUser,
  fetchSettings,
  getTenantFromURL,
} from "./BytebeamClient";
import FullPageMessage from "./components/Screens/common/FullPageMessage";
import { Mixpanel } from "./components/Screens/common/MixPanel";
import AlertRules from "./components/Screens/Settings/alerts/AlertRules";
import LoadingAnimation from "./components/common/Loader";
import SelectProjectModal from "./components/Screens/SelectProjectModal";
import NewProjectModal from "./components/Screens/NewProjectModal";
import MainNavbar from "./components/common/Navbar/MainNavbar";
import ViewDashboard from "./components/Screens/Dashboards/ViewDashboard";
import ActionsV3 from "./components/Screens/Actions/ActionsV3/ActionsV3";
import CompareDashboards from "./components/Screens/Dashboards/CompareDashboards";
import { showGetStartedForHost } from "./util";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.getActiveItem(window.location.pathname),
      user: null,
      settings: null,
      showNewProjectModal: false,
      showSelectProjectModal: false,
      currentTenant: null,
      loading: false,
    };
    window.addEventListener("popstate", () => {
      let active = this.getActiveItem(window.location.pathname);
      this.setState({ activeItem: active });
    });
  }

  getActiveItem(path) {
    let pathParts = path?.split("/");
    let activeItem = "";

    if (pathParts?.includes("dashboards")) {
      activeItem = "dashboards";
    } else if (pathParts?.includes("settings")) {
      activeItem = "settings";
    } else if (pathParts?.includes("device-management")) {
      activeItem = "device-management";
    } else if (pathParts?.includes("actions")) {
      activeItem = "actions";
    } else if (pathParts?.includes("alerts")) {
      activeItem = "alerts";
    } else if (pathParts?.includes("get-started")) {
      activeItem = "get-started";
    }

    return activeItem;
  }

  getCurrentUser = async () => {
    try {
      const res = await fetchCurrentUser();
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  getSettings = async () => {
    try {
      const res = await fetchSettings();
      return res;
    } catch (e) {
      console.log(e);
      // if status 500 error
      if (!e.message === "Fetch failed with status 403") return "invalid user";
    }
  };

  setUserState = (user) => {
    this.setState({ user });
  };

  setCurrentTenant = (tenant) => {
    this.setState({ currentTenant: tenant });
  };

  setShowNewProjectModal = (show) => {
    this.setState({ showNewProjectModal: show });
  };

  async componentDidMount() {
    let p1 = this.getCurrentUser();
    let p2 = this.getSettings();

    this.setState({ loading: true });
    const user = await p1;
    let settings = await p2;

    // Setting default tenancy as multi
    if (settings === undefined) {
      settings = { tenancy: "multi" };
    }

    let activeItem = this.state.activeItem;
    // if user exists
    if (user) {
      const hostname = window.location.hostname;
      Mixpanel.identify(`${user.email} - ${user.id}`);
      Mixpanel.track("Successful login");
      Mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $hostname: hostname,
      });

      // currently urlTenant and user.tenant_id are derived from getTenantFromURL() itself and are essentially same
      // TODO: need to realise if they can be merged into a single variable and decrease complexity
      const urlTenant = getTenantFromURL();

      // check if user has a redirect link
      // if user has access to the tenant on the redirect url then successfully redirect
      const redirectURL = window.localStorage.getItem("redirectURL");

      if (redirectURL) {
        const redirectTenant = getTenantFromURL(redirectURL);
        if (
          user.tenants &&
          user.tenants.length !== 0 &&
          user.tenants.includes(redirectTenant)
        ) {
          window.location = redirectURL;
        }
        // remove url from localstorage in any case
        window.localStorage.removeItem("redirectURL");
      }

      // CASE I: user is part of no tenants && no tenant is provided in url
      if ((!user.tenants || user.tenants.length === 0) && !user.tenant_id) {
        // Deciding to keep the current url in history, so once create tenant
        // is complete we can go back().
        if (window.location.pathname !== "/welcome") {
          console.log("Redirecting to welcome page");
          history.replace("/welcome");
        }
        activeItem = "welcome";
        this.setState({ user, settings, activeItem, loading: false });
      }
      // CASE II: no tenant is provided in url but user has has tenants' access && path is not `/welcome`
      else if (!user.tenant_id && window.location.pathname !== "/welcome") {
        // check if user has only one tenant or tenancy is single
        const isMultiTenant = settings && settings["tenancy"] === "multi";
        const isSingleTenant = settings && settings["tenancy"] === "single";

        // CASE A: MULTI-TENANT //
        // CASE 1: [SPECIAL CASE] user has access to 2 tenants and one of them is demo
        if (
          isMultiTenant &&
          user.tenants.length === 2 &&
          user.tenants.includes("demo")
        ) {
          console.log("isMultiTenant 1");

          // redirect to the other tenant than demos if only two tenants
          const tenant = user.tenants.find((t) => t !== "demo");
          window.location = `/projects/${tenant}/`;
        }
        // CASE 2: user has access to more than one tenants &&
        //         tenant provided in URL is one of them
        // TODO: this case will never happen smh
        else if (urlTenant.length >= 1 && user.tenants.includes(urlTenant)) {
          console.log("isMultiTenant 2");
          let tenant = urlTenant;
          this.setState({ currentTenant: tenant });
          user.tenant_id = tenant;

          // redirect to the tenant in URL if it is present in the user's tenants
          let homepage = this.decideHomePage(
            user.role.permissions.homepage,
            tenant,
            user
          );
          window.location = homepage;
          activeItem = this.getActiveItem(homepage);
        }
        // CASE 3: [SPECIAL CASE] tenants' list has one item which is empty string
        else if (user.tenants.length === 1 && user.tenants[0] === "") {
          // TODO: confirm if this is needed and can be merged with any other case
          console.log("isMultiTenant 3");

          // redirect to the only tenant that is demo if empty string tenant is added
          let tenant = "demo";
          this.setState({ currentTenant: tenant });
          user.tenant_id = tenant;
          let homepage = this.decideHomePage(
            user.role.permissions.homepage,
            tenant,
            user
          );
          window.location = homepage;
          activeItem = this.getActiveItem(homepage);
        }
        // CASE 4: user has access to only one tenant
        else if (user.tenants.length === 1) {
          console.log("isMultiTenant 4");
          let tenant = user.tenants[0];

          // redirect to the only tenant available
          window.location = `/projects/${tenant}/`;
        } else if (isSingleTenant && user.tenants.length > 0) {
          console.log("isSingleTenant 4");
          let tenant = settings.default_tenant;

          // redirect to bytebeam tenant if tenancy is single
          window.location = `/projects/${tenant}/`;
        } else {
          console.log("isSingleTenant 5");
          this.setState({ user: user, settings, showSelectProjectModal: true });
        }
        this.setState({ loading: false });
      }
      // CASE III: Tenant is provided in url && user has tenants' access too
      else {
        const permissions = user.role.permissions || {};
        this.setState({ currentTenant: user.tenant_id });

        if (
          !window.location.pathname ||
          window.location.pathname ===
            `/projects/${this.state.currentTenant}/` ||
          window.location.pathname ===
            `/projects/${this.state.currentTenant}` ||
          window.location.pathname === `/`
        ) {
          let homepage = this.decideHomePage(
            permissions.homepage,
            this.state.currentTenant,
            user
          );

          activeItem = this.getActiveItem(homepage);
          history.replace(homepage);
        }

        this.setState({ user, activeItem, settings, loading: false });
      }
    }
    // if user does not exist
    else {
      // store current URL to localstorage for redirection back to it after login
      window.localStorage.setItem("redirectURL", window.location.href);
      // If user data is not available, set loading to false
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.activeItem !== this.state.activeItem) {
      let p1 = this.getCurrentUser();
      const user = await p1;
      this.setState({ user });
    }
  }

  decideHomePage(homepagePerm, currentTenant, user) {
    let homepage = homepagePerm || `/`;
    if (
      user?.settings?.get_started?.skip !== true &&
      showGetStartedForHost &&
      (user?.role?.name === "admin" || user?.role?.name === "demorole")
    ) {
      homepage = `/projects/${currentTenant}/get-started`;
    } else if (homepage === "/") {
      homepage = `/projects/${currentTenant}/device-management/devices`;
    } else {
      homepage = `/projects/${currentTenant}${homepage}`;
    }
    return homepage;
  }

  render() {
    const {
      user,
      currentTenant,
      loading,
      showNewProjectModal,
      showSelectProjectModal,
      settings,
    } = this.state;

    if (loading) {
      return (
        <LoadingAnimation
          loaderContainerHeight="100vh"
          loadingText="Doing some heavy lifting"
          fontSize="1.5rem"
          marginTopText="12px"
        />
      );
    }

    if (user !== null) {
      const permissions = user?.role?.permissions || {};
      const tenants =
        user?.tenants?.filter((tenant) => tenant !== "demo") || [];

      if (showNewProjectModal) {
        return (
          <div className="dark">
            <Container className="app-container">
              <NewProjectModal
                isOpen={showNewProjectModal}
                setCurrentTenant={this.setCurrentTenant}
                onClose={() => this.setShowNewProjectModal(false)}
              />
            </Container>
          </div>
        );
      }

      const isMultiTenant = settings && settings["tenancy"] === "multi";
      const isSingleTenant = settings && settings["tenancy"] === "single";

      if (!isSingleTenant && showSelectProjectModal) {
        return (
          <div className="dark">
            <Container className="app-container">
              <SelectProjectModal
                tenants={tenants}
                isOpen={showSelectProjectModal}
                setCurrentTenant={this.setCurrentTenant}
                onClose={() => this.setState({ showSelectProjectModal: false })}
                user={user}
                setShowNewProjectModal={this.setShowNewProjectModal}
                currentTenant={currentTenant}
                disableCancelButton={true}
              />
            </Container>
          </div>
        );
      }

      return (
        <div className="dark">
          <Router basename={`/`}>
            <MainNavbar
              currentTenant={currentTenant}
              isSingleTenant={isSingleTenant}
              isMultiTenant={isMultiTenant}
              setCurrentTenant={this.setCurrentTenant}
            />
            <Container className="app-container">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  height: "calc(100% - 56.5px)",
                }}
              >
                <div className="app">
                  <Switch>
                    <Route
                      path={`/projects/${currentTenant}/dashboards/:id`}
                      render={(props) => (
                        <ViewDashboard
                          {...props}
                          id={parseInt(props.match.params.id)}
                          user={user}
                          settings={settings}
                          beingcompared={false}
                        />
                      )}
                    />

                    <Route
                      exact
                      path={`/projects/${currentTenant}/compare-dashboards/:dashboardId`}
                      render={(props) => {
                        return (
                          <CompareDashboards
                            user={user}
                            dashboardId={parseInt(
                              props.match.params.dashboardId
                            )}
                            currentTenant={currentTenant}
                            settings={settings}
                          />
                        );
                      }}
                    />

                    <Route path={`/projects/${currentTenant}/dashboards`}>
                      <DisplayIf
                        cond={
                          permissions.showDashboardsTab && settings !== null
                        }
                      >
                        <Dashboards
                          user={user}
                          currentTenant={currentTenant}
                          settings={settings}
                        />
                      </DisplayIf>
                    </Route>

                    <Route path={`/projects/${currentTenant}/alerts`}>
                      <DisplayIf
                        cond={user.role.name === "admin" && settings !== null}
                      >
                        <AlertRules />
                      </DisplayIf>
                    </Route>

                    <Route
                      path={`/projects/${currentTenant}/device-management`}
                    >
                      <DisplayIf cond={permissions.showDeviceManagementTab}>
                        <DeviceManagement
                          user={user}
                          currentTenant={currentTenant}
                        />
                      </DisplayIf>
                    </Route>
                    <Route
                      path={`/projects/${currentTenant}/device-management/devices`}
                    >
                      <DisplayIf cond={permissions.showDeviceManagementTab}>
                        <DeviceManagement
                          user={user}
                          currentTenant={currentTenant}
                        />
                      </DisplayIf>
                    </Route>
                    <Route
                      path={`/projects/${currentTenant}/device-management/firmwares`}
                    >
                      <DisplayIf cond={permissions.showDeviceManagementTab}>
                        <DeviceManagement
                          user={user}
                          currentTenant={currentTenant}
                        />
                      </DisplayIf>
                    </Route>
                    <Route
                      path={`/projects/${currentTenant}/device-management/configurations`}
                    >
                      <DisplayIf cond={permissions.showDeviceManagementTab}>
                        <DeviceManagement
                          user={user}
                          currentTenant={currentTenant}
                        />
                      </DisplayIf>
                    </Route>
                    <Route
                      path={`/projects/${currentTenant}/device-management/action-status`}
                    >
                      <DisplayIf cond={permissions.showDeviceManagementTab}>
                        <DeviceManagement
                          user={user}
                          currentTenant={currentTenant}
                        />
                      </DisplayIf>
                    </Route>

                    <Route path={`/projects/${currentTenant}/actions`}>
                      <DisplayIf cond={permissions.showActionsTab}>
                        <ActionsV3 />
                      </DisplayIf>
                    </Route>
                    <Route
                      path={`/projects/${currentTenant}/actions/live-actions`}
                    >
                      <DisplayIf cond={permissions.showActionsTab}>
                        <ActionsV3 />
                      </DisplayIf>
                    </Route>

                    <Route path={`/projects/${currentTenant}/settings`}>
                      <SettingsManagement currentTenant={currentTenant} />
                    </Route>
                    <Route
                      path={`/projects/${this.state.currentTenant}/get-started`}
                    >
                      <GetStartedScreen currentTenant={currentTenant} />
                    </Route>
                    <Route path="/welcome">
                      <WelcomeScreen
                        settings={settings}
                        setCurrentTenant={(tenant) =>
                          this.setCurrentTenant(tenant)
                        }
                      />
                    </Route>
                    <Route path="/projects/*/accessDenied">
                      <FullPageMessage
                        heading={"Access Denied"}
                        message={
                          "The operation you are trying to perform is not allowed."
                        }
                      />
                    </Route>
                    <Route path={`/projects/*`}>
                      <FullPageMessage
                        heading={"Switching Project"}
                        message={"Switching to your project"}
                      />
                    </Route>
                    <Route path="*">
                      <FullPageMessage
                        heading={"PAGE NOT FOUND"}
                        message={"This page is not available."}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>
            </Container>
          </Router>
        </div>
      );
    } else {
      return (
        <Container className="app-container">
          <LoginForm type="login" />
        </Container>
      );
    }
  }
}

export default App;
