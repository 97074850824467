import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Accordion, Button, Grid, Icon, Popup, Table } from "semantic-ui-react";
import useAsyncEffect from "../../common/useAsyncEffect";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  AlertGroups,
  AlertNotificationRule,
  AlertRule,
  CompositeCondition,
  Condition,
  EmailNotificationParams,
  SimpleCondition,
  SlackNotificationParams,
  SmsNotificationParams,
  WebhookNotificationParams,
  WhatsAppNotificationParams,
} from "../../../../util";
import { Mixpanel } from "../../common/MixPanel";
import CreateAlertRuleModal from "./CreateAlertRuleModal";
import {
  createAlertNotificationRule,
  createAlertRule,
  deleteAlertNotificationRule,
  deleteAlertRule,
  fetchAlertGroups,
  fetchAlertRules,
  startAlertRule,
  stopAlertRule,
  updateAlertNotificationRule,
  updateAlertRule,
} from "../../../../BytebeamClient";
import {
  ButtonIcon,
  DisplayIf,
  capitalizeFirstLetter,
  formatDuration,
} from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import CreateNotificationRuleModal from "./CreateNotificationRule";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { beamtoast } from "../../../common/CustomToast";
import { ActionsMenuWrapper } from "../../Actions/ActionsV3/ActionsV3";
import ActionsMenuTab from "../../Actions/ActionsV3/ActionsMenuTab";
import { ModuleStyledAccordion } from "../tenantsSettings/ModulePermissions";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { AccordionContentContainer } from "../tenantsSettings/TenantLevelSettings";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import styled from "styled-components";

export enum AlertModalStepType {
  ChooseAlertType,
  FillAlertTypeDetails,
}

export enum AlertRuleOperationType {
  Create = "create",
  Update = "update",
}

type AlertGroupsAccordionProps = {
  readonly isGroupAccordionActive: Record<string, boolean>;
  readonly setIsGroupAccordionActive: Dispatch<
    SetStateAction<Record<string, boolean>>
  >;
  readonly alertRules: AlertRule[];
  readonly formatCondition: (
    condition: Condition,
    isComposite: boolean
  ) => string;
  readonly setAlertRule: (alertRule: AlertRule) => void;
  readonly setAlertModalStep: (step: AlertModalStepType) => void;
  readonly setOperationType: (operationType: AlertRuleOperationType) => void;
  readonly setOpen: (open: boolean) => void;
  readonly alertsActionLoading: Record<string, boolean>;
  readonly renderToggleButton: (
    alertRule: AlertRule,
    status: string
  ) => React.JSX.Element;
  readonly renderDeleteButton: (alertRule: AlertRule) => React.JSX.Element;
};

const AlertGroupsAccordion = (props: AlertGroupsAccordionProps) => {
  const {
    isGroupAccordionActive,
    setIsGroupAccordionActive,
    alertRules,
    formatCondition,
    setAlertRule,
    setAlertModalStep,
    setOperationType,
    setOpen,
    alertsActionLoading,
    renderToggleButton,
    renderDeleteButton,
  } = props;

  // Group the alert rules by group names.
  const groupedAlerts = alertRules.reduce(
    (acc, alertRule) => {
      acc[alertRule.group] = acc[alertRule.group] || [];
      acc[alertRule.group].push(alertRule);
      return acc;
    },
    {} as Record<string, AlertRule[]>
  );

  // Sort the alert groups by name, so that 'default' group is always at the top and the rest are sorted alphabetically.
  const sortedAlertRulesGroups = Object.keys(groupedAlerts).sort((a, b) => {
    if (a === "default") return -1;
    if (b === "default") return 1;
    return a.localeCompare(b);
  });

  const handleAccordionClick = (group: string) => {
    // Toggle the accordion state to close when it is open and undefined (default state)
    setIsGroupAccordionActive((prevState) => ({
      ...prevState,
      // If the group is false, then set it to true, else set it to false
      [group]: prevState[group] === false,
    }));
  };

  return (
    <>
      {sortedAlertRulesGroups.length > 0 ? (
        sortedAlertRulesGroups.map((alert_group) => (
          <ModuleStyledAccordion key={alert_group}>
            <Accordion fluid>
              <Accordion.Title
                active={isGroupAccordionActive[alert_group] ?? true}
                onClick={() => handleAccordionClick(alert_group)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <StyledHeader
                    as="h3"
                    style={{
                      margin: "0px",
                      fontSize: "1.1rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {alert_group}
                  </StyledHeader>
                  <Icon
                    style={{ fontSize: "24px", alignItems: "center" }}
                    name={
                      isGroupAccordionActive[alert_group] ?? true
                        ? "angle down"
                        : "angle right"
                    }
                  />
                </div>
              </Accordion.Title>
              <Accordion.Content
                active={isGroupAccordionActive[alert_group] ?? true}
              >
                <ThinDivider style={{ margin: "15px 0px 15px 0px" }} />
                <AccordionContentContainer>
                  <Table celled fixed id="alertRulesTable">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          Input Stream
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>Condition</Table.HeaderCell>
                        <Table.HeaderCell>Criticality</Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          Thresholds
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          Last Processed Timestamp
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {groupedAlerts[alert_group].map((alertRule) => {
                        const lpt = alertRule.last_processed_timestamps || {};

                        return (
                          <Table.Row key={alertRule.id}>
                            <Table.Cell>
                              <TextWithToolTip notBold text={alertRule.name} />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                notBold
                                text={alertRule.stream}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip
                                notBold
                                text={formatCondition(
                                  alertRule.condition,
                                  false
                                )}
                              />
                            </Table.Cell>
                            <Table.Cell>{alertRule.criticality}</Table.Cell>
                            <Table.Cell>
                              Activation:{" "}
                              {formatDuration(
                                alertRule.activation_threshold_seconds
                              )}
                              <br />
                              Deactivation:{" "}
                              {formatDuration(
                                alertRule.deactivation_threshold_seconds
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {capitalizeFirstLetter(alertRule.status ?? "-")}
                            </Table.Cell>
                            {Object.keys(lpt).length !== 0 ? (
                              <Table.Cell>
                                {Object.keys(lpt).map((key) => {
                                  return (
                                    <>
                                      {lpt[key] ? (
                                        <div key={key}>
                                          shard-{key}:{" "}
                                          {moment
                                            .duration(
                                              lpt[key] - new Date().valueOf()
                                            )
                                            .humanize()}{" "}
                                          ago
                                        </div>
                                      ) : (
                                        "--"
                                      )}
                                    </>
                                  );
                                })}
                              </Table.Cell>
                            ) : (
                              <Table.Cell textAlign="center">--</Table.Cell>
                            )}
                            <Table.Cell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "12px",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <ButtonIcon
                                  link
                                  name="edit"
                                  title="Edit Alert Rule"
                                  onClick={() => {
                                    setAlertRule(alertRule);
                                    setAlertModalStep(
                                      AlertModalStepType.FillAlertTypeDetails
                                    );
                                    setOperationType(
                                      AlertRuleOperationType.Update
                                    );
                                    setOpen(true);
                                  }}
                                />
                                {alertsActionLoading[alertRule.name] ? (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      marginRight: "6px",
                                    }}
                                  >
                                    <LoadingAnimation
                                      loaderSize="14px"
                                      loaderBorderSize="3px"
                                    />
                                  </span>
                                ) : (
                                  renderToggleButton(
                                    alertRule,
                                    alertRule.status ?? "stopped"
                                  )
                                )}
                                {renderDeleteButton(alertRule)}
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </AccordionContentContainer>
              </Accordion.Content>
            </Accordion>
          </ModuleStyledAccordion>
        ))
      ) : (
        <ErrorMessage
          message={"No Alert Rules found!"}
          marginTop="4em"
          marginBottom="4em"
        />
      )}
    </>
  );
};

type AlertRulesTableProps = {
  readonly alertRules: AlertRule[];
  readonly alertGroups: AlertGroups;
  readonly setLoading: (loading: boolean) => void;
  readonly onChange: (showLoading: boolean) => void;
  readonly newAlertRuleName: string;
  readonly setNewAlertRuleName: (name: string) => void;
};

function AlertRulesTables(props: AlertRulesTableProps) {
  const {
    alertRules,
    alertGroups,
    setLoading,
    onChange,
    newAlertRuleName,
    setNewAlertRuleName,
  } = props;

  const [open, setOpen] = useState(false);
  const [alertRule, setAlertRule] = useState<AlertRule>({} as AlertRule);
  const [alertModalStep, setAlertModalStep] = useState<number>(
    AlertModalStepType.ChooseAlertType
  );
  const [operationType, setOperationType] = useState<string>(
    AlertRuleOperationType.Create
  );
  const [alertsActionLoading, setAlertsActionLoading] = useState<{
    [key: string]: boolean;
  }>({});
  // Alert group accordion active state
  const [isGroupAccordionActive, setIsGroupAccordionActive] = useState<
    Record<string, boolean>
  >({});

  const formatCondition = (
    condition: Condition,
    wrapInBrackets: boolean = false
  ) => {
    const compositeOperators = ["and", "or"];
    const noDataOperator = "no_data";

    if (condition.operator === noDataOperator) {
      return "No Data";
    } else if (compositeOperators.includes(condition.operator)) {
      const conditions = (condition as CompositeCondition).conditions;
      const s = conditions
        .map((c) => formatCondition(c, true))
        .join(` ${condition.operator} `);
      return wrapInBrackets ? `(${s})` : s;
    } else {
      const c = condition as SimpleCondition;
      const s = `${c.field} ${c.operator} ${c.value}`;

      return wrapInBrackets ? `(${s})` : s;
    }
  };

  const _startAlertRule = async (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    try {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: true,
      }));
      await startAlertRule(alertRuleId);
      setNewAlertRuleName("");
      // Sleep for a 5 seconds to allow the alert rule to start
      await new Promise((resolve) => setTimeout(resolve, 5000));

      beamtoast.success(`Started alert rule "${alertRuleName}"`);
      onChange(false);
    } catch (e) {
      beamtoast.error(`Failed to start alert rule "${alertRuleName}"`);
      console.log(e);
    } finally {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: false,
      }));
    }
  };

  const _stopAlertRule = async (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    try {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: true,
      }));
      await stopAlertRule(alertRuleId);
      // Sleep for a 5 seconds to allow the alert rule to stop
      await new Promise((resolve) => setTimeout(resolve, 5000));
      beamtoast.success(`Stopped alert rule "${alertRuleName}"`);
      onChange(false);
    } catch (e) {
      beamtoast.error(`Failed to stop alert rule "${alertRuleName}"`);
      console.log(e);
    } finally {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: false,
      }));
    }
  };

  const renderToggleButton = (alertRule: AlertRule, status: string) => {
    if (status === "stopped") {
      return (
        <Popup
          content="Click here to start Alert"
          inverted
          position="top center"
          open={
            alertRule.name === newAlertRuleName &&
            isGroupAccordionActive?.[`${alertRule.group}`] !== false
          }
          trigger={
            <ButtonIcon
              link
              name="play"
              title="Start Alert Rule"
              onClick={() => _startAlertRule(alertRule)}
            />
          }
        />
      );
    } else {
      return (
        <ButtonIcon
          link
          name="pause"
          title="Stop Alert Rule"
          onClick={() => _stopAlertRule(alertRule)}
        />
      );
    }
  };

  // Passed alertName also, and show that in delete confirmation.
  const renderDeleteButton = (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    const isNotificationRuleExist =
      (alertRule?.notification_rules ?? []).length > 0;
    const isAlertRuleRunning = alertRule.status === "running";

    if (isNotificationRuleExist || isAlertRuleRunning) {
      return (
        <Popup
          trigger={
            <ButtonIcon
              link
              name="trash"
              disabled={isAlertRuleRunning || isNotificationRuleExist}
            />
          }
          content={
            isAlertRuleRunning
              ? "Alert Rule is in running state"
              : "Alert Rule is assigned to some Notification Rule"
          }
          inverted
          position="top center"
        />
      );
    } else {
      return (
        <ConfirmationModal
          trigger={<ButtonIcon link name="trash" title="Delete Alert Rule" />}
          prefixContent="Delete Alert Rule"
          expectedText={alertRuleName}
          message={
            <ConfirmationModalMessage
              name={alertRuleName}
              type={"Alert Rule"}
              specialMessage="Note that this will also delete any alerts created by this rule"
            />
          }
          onConfirm={async () => {
            setLoading(true);

            try {
              await deleteAlertRule(alertRuleId);
              beamtoast.success(`Deleted alert rule "${alertRuleName}"`);
            } catch (e) {
              beamtoast.error(`Failed to delete alert rule "${alertRuleName}"`);
              console.log(e);
            } finally {
              onChange(true);
            }
          }}
        />
      );
    }
  };

  const handleCreateAlertType = async (alertRule: AlertRule) => {
    alertRule.name = alertRule.name.trim();
    const alertRuleName = alertRule.name;

    setNewAlertRuleName("");
    try {
      // Remove id from alertRule
      const rule: any = { ...alertRule };
      delete rule.id;

      await createAlertRule(rule);

      setNewAlertRuleName(rule.name);

      beamtoast.success(`Created new alert rule ${alertRuleName}`);
      Mixpanel.track("Created Alert Type", {});
      onChange(true);
    } catch (e) {
      beamtoast.error(`Failed to create alert rule ${alertRuleName}`);
      Mixpanel.track("Failure", {
        type: "Alert Type creation",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  const handleEditAlertType = async (alertRule: AlertRule) => {
    alertRule.name = alertRule.name.trim();
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;

    try {
      setLoading(true);
      await updateAlertRule(alertRuleId, alertRule);

      beamtoast.success(`Edited alert rule "${alertRuleName}"`);
      Mixpanel.track("Edited Alert Rule", {
        id: alertRuleId,
        name: alertRule.name,
      });

      // Wait for 5s for the service to come back up
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } catch (e) {
      beamtoast.error(`Failed to edit alert rule "${alertRuleName}"`);

      Mixpanel.track("Failure", {
        type: "Alert Rule edit",
        error: JSON.stringify(e),
      });
      console.log(e);
    } finally {
      onChange(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setAlertModalStep(AlertModalStepType.ChooseAlertType);
    setOperationType(AlertRuleOperationType.Create);
    setAlertRule({} as AlertRule);
  };

  useEffect(() => {
    setTimeout(() => {
      setNewAlertRuleName("");
    }, 60000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid>
      <CreateAlertRuleModal
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleModalClose}
        title={
          operationType === AlertRuleOperationType.Create
            ? "New Alert Rule"
            : "Edit Alert Rule"
        }
        alertRule={alertRule}
        operationType={operationType}
        alertModalStep={alertModalStep}
        onSubmit={
          operationType === AlertRuleOperationType.Create
            ? handleCreateAlertType
            : handleEditAlertType
        }
        alertRules={alertRules}
        alertGroups={alertGroups}
      />
      <Grid.Row>
        {alertRules.length > 0 && (
          <Grid.Column
            width={8}
            style={{ alignItems: "center", display: "flex", gap: "8px" }}
          >
            <Button
              title="Expand All Accordions"
              basic
              id="ExpandAllAccordionButton"
              disabled={Object.values(isGroupAccordionActive).every(
                (value) => value === true
              )}
              onClick={() => {
                let temp = {};
                for (let key of alertGroups) {
                  temp[key] = true;
                }
                setIsGroupAccordionActive(temp);
              }}
            >
              <Icon name="plus square outline" />
              <strong>Expand all</strong>
            </Button>
            <Button
              title="Collapse All Accordions"
              basic
              id="CollapseAllAccordionButton"
              disabled={
                Object.values(isGroupAccordionActive).length > 0 &&
                Object.values(isGroupAccordionActive).every(
                  (value) => value === false
                )
              }
              onClick={() => {
                let temp = {};
                for (let key of alertGroups) {
                  temp[key] = false;
                }
                setIsGroupAccordionActive(temp);
              }}
            >
              <Icon name="minus square outline" />
              <strong>Collapse all</strong>
            </Button>
          </Grid.Column>
        )}
        <Grid.Column width={alertGroups.length > 1 ? 8 : 16}>
          <Button
            id="addAlertRuleButton"
            primary
            floated="right"
            icon
            labelPosition="left"
            onClick={() => {
              setAlertModalStep(AlertModalStepType.ChooseAlertType);
              setOperationType(AlertRuleOperationType.Create);
              setOpen(true);
            }}
          >
            <Icon name="plus" />
            Alert Rule
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column>
          <AlertGroupsAccordion
            isGroupAccordionActive={isGroupAccordionActive}
            setIsGroupAccordionActive={setIsGroupAccordionActive}
            alertRules={alertRules}
            formatCondition={formatCondition}
            setAlertRule={setAlertRule}
            setAlertModalStep={setAlertModalStep}
            setOperationType={setOperationType}
            setOpen={setOpen}
            alertsActionLoading={alertsActionLoading}
            renderToggleButton={renderToggleButton}
            renderDeleteButton={renderDeleteButton}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export enum NotificationRuleOperationType {
  Create = "create",
  Update = "update",
}

export enum NotificationModalStepType {
  ChooseNotificationType,
  FillNotificationTypeDetails,
}

interface DeleteAlertNotificationRuleButtonProps {
  readonly onChange: (showLoading: boolean) => void;
  readonly alertRuleLastProcessedAt:
    | Record<number, string | number>
    | undefined;
  readonly notificationRule: AlertNotificationRule;
  readonly alertRuleName: string;
}

function DeleteAlertNotificationRuleButton(
  props: DeleteAlertNotificationRuleButtonProps
) {
  const {
    alertRuleName,
    notificationRule,
    alertRuleLastProcessedAt,
    onChange,
  } = props;
  const channelType = notificationRule.channel_type;
  const isAlertRuleProcessed =
    alertRuleLastProcessedAt &&
    Object.values(alertRuleLastProcessedAt).length > 0;

  return isAlertRuleProcessed ? (
    <Popup
      inverted
      position="top center"
      content="Alert notification can't be deleted once alert rule is processed."
      trigger={
        <ButtonIcon
          link
          name="trash"
          title="Delete Notification Rule"
          disabled={isAlertRuleProcessed}
        />
      }
    />
  ) : (
    <ConfirmationModal
      trigger={
        <ButtonIcon link name="trash" title="Delete Notification Rule" />
      }
      prefixContent="Delete Alert Notification Rule"
      expectedText={`${alertRuleName} - ${channelType}`}
      message={
        <ConfirmationModalMessage
          name={`${alertRuleName} - ${channelType}`}
          type={"Alert Notification Rule"}
          specialMessage="Note that this will also delete any existing notifications for this alert rule."
        />
      }
      onConfirm={async () => {
        try {
          await deleteAlertNotificationRule(notificationRule);
          beamtoast.success(
            `Deleted alert notification rule "${alertRuleName} - ${channelType}"`
          );
        } catch (e) {
          beamtoast.error(
            `Failed to delete alert rule "${alertRuleName} - ${channelType}"`
          );
          console.log(e);
        } finally {
          onChange(true);
        }
      }}
    />
  );
}

const StyleNotificationChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  line-height: 18px;
  gap: 12px;
`;

type AlertNotificationRulesProps = {
  readonly alertRules: AlertRule[];
  readonly onChange: (showLoading: boolean) => void;
};

function AlertNotificationRules(props: AlertNotificationRulesProps) {
  const { alertRules, onChange } = props;

  const [open, setOpen] = useState(false);
  const [notificationRule, setNotificationRule] = useState<
    AlertNotificationRule | undefined
  >();
  const [notificationModalStep, setNotificationModalStep] = useState<number>(
    NotificationModalStepType.ChooseNotificationType
  );
  const [operationType, setOperationType] = useState<string>(
    NotificationRuleOperationType.Create
  );

  const renderNotificationChannel = (
    notificationRule: AlertNotificationRule
  ) => {
    if (notificationRule.channel_type === "slack") {
      const params =
        notificationRule.channel_parameters as SlackNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="slack" style={{ fontSize: "17px", fontWeight: "900" }} />
          <TextWithToolTip text={params.slack_channel} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "email") {
      const params =
        notificationRule.channel_parameters as EmailNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="mail" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.emails.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "webhook") {
      const params =
        notificationRule.channel_parameters as WebhookNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="external" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.url} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "sms") {
      const params =
        notificationRule.channel_parameters as SmsNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="comment" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.phone_numbers.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "whatsapp") {
      const params =
        notificationRule.channel_parameters as WhatsAppNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon
            name="whatsapp"
            style={{ fontSize: "17px", fontWeight: "900" }}
          />
          <TextWithToolTip text={params.phone_numbers.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    }
  };

  const renderDeleteButton = (
    alertRule: AlertRule,
    notificationRule: AlertNotificationRule
  ) => {
    return (
      <DeleteAlertNotificationRuleButton
        alertRuleLastProcessedAt={alertRule.last_processed_timestamps}
        notificationRule={notificationRule}
        alertRuleName={alertRule.name}
        onChange={onChange}
      />
    );
  };

  const doNotificationRulesExist = () => {
    let exist: boolean = false;

    if (alertRules.length !== 0) {
      for (const alertRule of alertRules) {
        if (alertRule.notification_rules?.length !== 0) {
          exist = true;
          break;
        }
      }
    }

    return exist;
  };

  const handleCreateAlertNotificationRule = async (
    notificationRule: AlertNotificationRule
  ) => {
    const { channel_type: channelType, alert_rule_id: alertRuleId } =
      notificationRule;
    const alertRuleName = alertRules.find(
      (rule) => rule.id === alertRuleId
    )?.name;

    try {
      await createAlertNotificationRule(notificationRule);
      beamtoast.success(
        `Created alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Created Notification Rule", {});
    } catch (e) {
      console.error("Error creating alert notification rule:", e);
      beamtoast.error(
        `Failed to create alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Failure", {
        type: "Alert Notification Rule creation",
        error: JSON.stringify(e instanceof Error ? e.message : e),
      });
    } finally {
      onChange(true);
    }
  };

  const handleEditAlertNotificationRule = async (
    notificationRule: AlertNotificationRule
  ) => {
    const { channel_type: channelType, alert_rule_id: alertRuleId } =
      notificationRule;
    const alertRuleName = alertRules.find(
      (rule) => rule.id === alertRuleId
    )?.name;

    try {
      await updateAlertNotificationRule(notificationRule);
      beamtoast.success(
        `Edited alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Updated Notification Rule", {});
    } catch (e) {
      console.error("Error editing alert notification rule:", e);
      beamtoast.error(
        `Failed to edit alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Failure", {
        type: "Alert Notification Rule editing",
        error: JSON.stringify(e instanceof Error ? e.message : e),
      });
    } finally {
      onChange(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setOperationType(NotificationRuleOperationType.Create);
    setNotificationRule(undefined);
  };

  return (
    <Grid>
      <CreateNotificationRuleModal
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleModalClose}
        title={
          operationType === NotificationRuleOperationType.Create
            ? "New Notification Rule"
            : "Edit Notification Rule"
        }
        onSubmit={
          operationType === NotificationRuleOperationType.Create
            ? handleCreateAlertNotificationRule
            : handleEditAlertNotificationRule
        }
        alertRules={alertRules}
        alertNotificationRule={notificationRule}
        notificationModalStep={notificationModalStep}
        operationType={
          operationType === NotificationRuleOperationType.Create
            ? "create"
            : "update"
        }
      />
      <Grid.Row>
        <Grid.Column>
          <Button
            id="addNotificationRuleButton"
            primary
            floated="right"
            icon
            labelPosition="left"
            onClick={() => {
              setOperationType(AlertRuleOperationType.Create);
              setNotificationModalStep(
                NotificationModalStepType.ChooseNotificationType
              );
              setOpen(true);
            }}
          >
            <Icon name="plus" />
            Notification Rule
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table id="notificationRulesTable" celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Alert Name</Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Notification Channel
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>Interval</Table.HeaderCell>
                <Table.HeaderCell>Last Notified At</Table.HeaderCell>
                <Table.HeaderCell>Timezone</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {alertRules.length !== 0 && doNotificationRulesExist() ? (
                alertRules.map((alertRule) => {
                  return alertRule.notification_rules?.map(
                    (notificationRule) => {
                      return (
                        <Table.Row key={notificationRule.id}>
                          <Table.Cell>
                            <TextWithToolTip text={alertRule.name} />
                          </Table.Cell>
                          <Table.Cell>
                            {renderNotificationChannel(notificationRule)}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDuration(notificationRule.interval_seconds)}
                          </Table.Cell>
                          <Table.Cell>
                            {notificationRule.last_notified_at
                              ? moment(
                                  notificationRule.last_notified_at
                                ).fromNow()
                              : "-"}
                          </Table.Cell>
                          <Table.Cell>
                            {notificationRule.timezone ?? "UTC"}
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "12px",
                                flexWrap: "nowrap",
                              }}
                            >
                              <ButtonIcon
                                link
                                name="edit"
                                title="Edit Notification Rule"
                                onClick={() => {
                                  setNotificationRule(notificationRule);
                                  setOperationType(
                                    NotificationRuleOperationType.Update
                                  );
                                  setNotificationModalStep(
                                    NotificationModalStepType.FillNotificationTypeDetails
                                  );
                                  setOpen(true);
                                }}
                              />
                              {renderDeleteButton(alertRule, notificationRule)}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <ErrorMessage message={"No Notification Rules found!"} />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default function AlertRules() {
  const [alertRules, setAlertRules] = useState<AlertRule[]>([]);
  const [alertGroups, setAlertGroups] = useState<AlertGroups>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [newAlertRuleName, setNewAlertRuleName] = useState<string>("");

  const { path } = useRouteMatch();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>("actions summary");

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  useEffect(() => {
    let activeItem: string;

    switch (location.pathname) {
      case `${path}/alert-rules`:
        activeItem = "Alert Rules";
        break;

      case `${path}/alert-notification`:
        activeItem = "Notification Settings";
        break;

      default:
        activeItem = "Alert Rules";
        break;
    }

    handleItemClick(activeItem);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdate = async (showLoading: boolean) => {
    showLoading && setLoading(true);
    try {
      const res = await fetchAlertRules();
      setAlertRules(res);
      const alertGroups = await fetchAlertGroups();
      setAlertGroups(alertGroups);
    } catch (error) {
      console.log(error);
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect(() => handleUpdate(true), []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  let loadingText = "Loading...";
  if (location.pathname.includes("alert-rules")) {
    loadingText = "Loading Alert Rules";
  } else if (location.pathname.includes("alert-notification")) {
    loadingText = "Loading Notification Rules";
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="calc(100vh - 130px)"
        fontSize="1.5rem"
        loadingText={loadingText}
      />
    );
  }

  return (
    <>
      <div
        className="desktop-view"
        style={{ display: "flex", alignItems: "center", gap: 10 }}
      >
        <ActionsMenuWrapper className="desktop-view">
          <ActionsMenuTab
            firstElement
            name={"Alert Rules"}
            icon={"wait"}
            to={`${path}/alert-rules`}
            displayIf={true}
            active={activeItem === "Alert Rules"}
            onClick={() => {
              handleItemClick("Alert Rules");
            }}
          />
          <ActionsMenuTab
            firstDivider
            lastElement
            name={"Notification Settings"}
            icon={"boxes"}
            to={`${path}/alert-notification`}
            displayIf={true}
            active={activeItem === "Notification Settings"}
            onClick={() => {
              handleItemClick("Notification Settings");
            }}
          />
        </ActionsMenuWrapper>
      </div>
      <Switch>
        <Route exact path={`${path}/alert-rules`}>
          <DisplayIf cond={true}>
            <AlertRulesTables
              alertRules={alertRules}
              alertGroups={alertGroups}
              setLoading={setLoading}
              onChange={handleUpdate}
              newAlertRuleName={newAlertRuleName}
              setNewAlertRuleName={setNewAlertRuleName}
            />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/alert-notification`}>
          <DisplayIf cond={true}>
            <AlertNotificationRules
              alertRules={alertRules}
              onChange={handleUpdate}
            />
          </DisplayIf>
        </Route>
        <Redirect exact from={path} to={`${path}/alert-rules`} />
      </Switch>
    </>
  );
}
